import { useState } from 'react';
import Arrow from '../icons/Arrow';
import { useRouter } from 'next/router';
import UseLocale from '@/hooks/useLocale';

const Heading = ({
  t,
  totalProducts,
  sortKeys,
  handleSorting,
  allProducts,
  categoryName,
  category_description = null,
}) => {
  const [sortName, setSortName] = useState('Discount From High To Low');
  const { query, locale: lang } = useRouter();
  const { locale } = UseLocale(lang);

  console.log(sortKeys);
  return (
    <div className='max-sm:mx-auto max-sm:w-[97%]'>
      <div>
        <div className='mb-1 flex items-center justify-between gap-4'>
          <div className='flex flex-grow items-center justify-between gap-4'>
            <h1 className='text-xl font-bold uppercase max-sm:text-sm'>
              {(query?.brand
                ? allProducts?.at(0)?.option_text_brand?.at(0)
                : categoryName) || ''}
            </h1>
            <p className='md:text-md flex gap-1 text-sm font-semibold text-gray-500'>
              <span className='font-bold'>{allProducts?.length || 0}</span>
              <span>{locale === 'ar' ? 'منتج من' : 'Product of'}</span>
              <span className='font-bold'>{totalProducts || 0}</span>
            </p>
          </div>
          {allProducts?.length ? (
            <div className='group relative hidden w-full min-w-52 cursor-pointer items-center justify-between gap-1 rounded-md border p-2 text-[15px] text-[#586A84] sm:w-48 lg:flex'>
              <span className='cursor-pointer font-semibold max-sm:text-[11px]'>
                {t(sortName)}
              </span>
              <Arrow />
              <ul className='absolute left-0 top-full z-50 hidden w-full min-w-52 rounded-md border bg-white shadow-lg *:cursor-pointer *:text-[15px] *:text-black group-hover:block'>
                {sortKeys?.map((item, key) => (
                  <li
                    key={`sortkey-${key}`}
                    onClick={() => {
                      handleSorting({ keys: item?.key });
                      setSortName(item?.label);
                    }}
                    className={`hover:text-secondary-700 px-4 py-2 hover:bg-[#586A84]/30 ${
                      sortKeys?.length - 1 == key ? 'mb-0' : 'mb-1.5'
                    } `}
                  >
                    {t(item?.label)}
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>

        {category_description ? (
          <p className='text-[15px] text-[#615656] md:text-base'>
            {category_description}
          </p>
        ) : null}
      </div>
    </div>
  );
};

export default Heading;
